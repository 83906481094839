<template>
  <div class="safety-card">
    <div
      v-for="(card, index) in list"
      :key="index"
      class="safety-card-item"
    >
      <div class="safety-card-item-row">
        <div
          class="safety-card-item-row-line"
          :style="{ background: colors[index % 2] }"
        ></div>
        <div class="safety-card-item-row-title">
          <img :src="card.icon" />
          <div :style="{ color: colors[index % 2] }">{{ card.label }}</div>
        </div>
        <div class="safety-card-item-row-value">
          <div class="safety-card-item-row-value-number">
            <count-to :endVal="card.value"></count-to>
          </div>
          <div class="safety-card-item-row-value-unit">{{ card.unit }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from "@/components/count-to.vue"
export default {
  components: { countTo },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => ["#63E881", "#66BBF9"],
    },
  },
}
</script>

<style lang="less" scoped>
.safety-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  &-item {
    width: 100%;
    flex: 1;
    height: 60px;
    position: relative;
    background: url("../../../assets/images/img_safty_card.png");
    background-size: 100% 100%;
    & + & {
      margin-left: 3px;
    }
    &-row {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-line {
        height: 1px;
        width: 100%;
      }

      &-title {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        & > img {
          width: 14px;
          height: auto;
          margin-right: 8px;
        }
        & > div {
          font-size: 14px;
          color: #63e881;
          line-height: 21px;
        }
      }
      &-value {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        &-number {
          font-weight: bold;
          font-size: 24px;
          color: #ffffff;
          line-height: 31px;
        }
        &-unit {
          font-size: 14px;
          color: #ffffff;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
