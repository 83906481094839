<template>
  <div class="boundary">
    <div class="boundary-card">
      <div>报警总数</div>
      <div>
        <count-to :end-val="list.length"></count-to>
      </div>
    </div>
    <div class="boundary-table">
      <zy-scroll-table
        :data="list"
        :height="120"
        :row-height="42"
      >
        <zy-scroll-table-column
          label="位置"
          prop="address"
          width="180px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="报警时间"
          align="center"
          prop="time"
          width="150px"
        ></zy-scroll-table-column>
      </zy-scroll-table>
    </div>
  </div>
</template>

<script>
import components from "@/jxyh/components"
import CountTo from "@/components/count-to"
export default {
  ...components(["ZyScrollTable", "ZyScrollTableColumn"], { CountTo }),
  data() {
    return {
      list: [
        {
          address: "4#栋-12层",
          time: "2024-07-14 10:12:23",
        },
        {
          address: "3#栋-1层阳台",
          time: "2024-07-14 10:15:23",
        },
      ],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const list = []
      for (let i = 0; i < 100; i++) {
        const build = this.random(1, 22)
        const floor = this.random(1, 40)
        const day = this.random(10, 17)
        const hour = this.random(10, 18)
        const min = this.random(10, 60)
        const s = this.random(10, 60)
        const address = `${build}#-${floor}层`
        const time = `2024-07-${day} ${hour}:${min}:${s}`
        list.push({
          address,
          time,
        })
      }
      this.list = list
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
  },
}
</script>

<style lang="less" scoped>
.boundary {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  &-card {
    width: 131px;
    height: 160px;
    flex-shrink: 0;
    background: url(../../../assets/images/img_bjfhbj_bg.png);
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > div:nth-child(1) {
      font-size: 14px;
      color: #fc4a66;
      line-height: 21px;
    }
    & > div:nth-child(2) {
      font-weight: bold;
      font-size: 26px;
      color: #ffffff;
      line-height: 33px;
    }
  }
  &-table {
    flex-shrink: 0;
    margin-left: 22px;
  }
}
</style>
