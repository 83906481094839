import { get } from "@/utils/request"
// 視頻ai檢測
export function videoAiDetection() {
  const requestProjectId = require("@/utils/requestProjectId")
  const projectId = requestProjectId.changeProject("39941432740131840", "47261066628936704")
  return get("/pms/view/videoAiDetection", { projectId })
}
// 专项检查
export function specialInspections(params) {
  return get("/pms/view/specialInspections", params)
}
// 图标数据
export function diagramHead() {
  return get("/pms/pmsDiagramHead/detail")
}
//重大危险源监测-塔吊检测
export function majorHazards() {
  return get("/pms/view/majorHazards")
}
// 隐患概括
export function checkProblemOverview() {
  return get("/pms/view/checkProblem/overview")
}
// 隐患整改情况
export function rectificationSituation() {
  return get("/pms/view/rectificationSituation")
}
//无人塔吊
export function contentList(params) {
  return get("/pms/content/list", params)
}
