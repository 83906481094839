<template>
  <div>
    <mark-monitor :point.sync="point" @close="closeMarkMonitor"></mark-monitor>
    <zy-layout>
      <zy-layout-column animation="left">
        <zy-title @more="showAiDialog">视频AI监测</zy-title>
        <zy-scroll-table :data="aiList" :height="216" :row-height="72">
          <zy-scroll-table-column label="抓拍照片" prop="eventPicUrl">
            <template slot-scope="{ row }">
              <div style="display: flex">
                <zy-image class="ai-img" :src="row.eventPicUrl" preview></zy-image>
              </div>
            </template>
          </zy-scroll-table-column>
          <zy-scroll-table-column label="报警类型" prop="eventTypeStr" width="230px"></zy-scroll-table-column>
          <zy-scroll-table-column label="抓拍时间" prop="eventHappenTime" align="right"
            width="200px"></zy-scroll-table-column>
        </zy-scroll-table>
        <zy-title class="jxyh-margin"> 边界防护报警 </zy-title>
        <boundary></boundary>
        <zy-title class="jxyh-margin"> 专项检查 </zy-title>
        <card :list="specialInspectionsCards"></card>
        <zy-scroll-table :data="specialInspectionsInfo.checkTheBatch" :height="210" :row-height="42"
          class="jxyh-margin">
          <zy-scroll-table-column label="检查批次名称" prop="inspectionBatchName" width="180px">
          </zy-scroll-table-column>
          <zy-scroll-table-column label="类型" prop="checkTypeStr" width="120px"></zy-scroll-table-column>
          <zy-scroll-table-column label="负责人" prop="principalName" width="120px"></zy-scroll-table-column>
          <zy-scroll-table-column label="计划开始时间" prop="createTime"></zy-scroll-table-column>
        </zy-scroll-table>
      </zy-layout-column>
      <zy-layout-column animation="center">
        <div>
          <zy-title> 数字工地 </zy-title>
          <zy-marks :src="points.imageUrl" v-model="points.points" :basis-width="points.imageWidths"
            @clickPoint="markClickPoint" @clickImg="showMaxMarks">
          </zy-marks>
        </div>
        <div>
          <zy-title class="jxyh-margin"> 重大危险源监测 </zy-title>
          <zy-scroll-table :data="majorHazardsList" :height="255" :row-height="51" style="margin-top: 10px">
            <zy-scroll-table-column label="时间" prop="createTime" width="100px" align="center">
              <template slot-scope="{ row }">
                <div class="major-hazards-time">{{ row.createTime }}</div>
              </template>
            </zy-scroll-table-column>
            <zy-scroll-table-column label="回转(°)" prop="rotationAngle" width="90px"
              align="right"></zy-scroll-table-column>
            <zy-scroll-table-column label="幅度(m)" prop="margin" width="90px" align="right"></zy-scroll-table-column>
            <zy-scroll-table-column label="吊钩高度(m)" prop="height" width="100px" align="right"></zy-scroll-table-column>
            <zy-scroll-table-column label="风速(m/s)" prop="windSpeed" width="90px"
              align="right"></zy-scroll-table-column>
            <zy-scroll-table-column label="吊重(kg)" prop="loadWeight" width="90px"
              align="right"></zy-scroll-table-column>
            <zy-scroll-table-column label="力矩比(%)" prop="torqueRate" width="90px"
              align="right"></zy-scroll-table-column>
            <zy-scroll-table-column label="方向(°)" prop="inclination" width="90px"
              align="right"></zy-scroll-table-column>
          </zy-scroll-table>
        </div>
      </zy-layout-column>
      <zy-layout-column animation="right">
        <zy-title>隐患概况</zy-title>
        <card style="margin-top: 10px" :list="checkProblemOverviewCards" :colors="['#66BBF9', '#63E881']"></card>
        <div class="check-problem" v-if="checkProblemOverviewInfo != null">
          <div class="check-problem-chart">
            <zy-chart-ring :data="checkProblemOverviewInfo.checkItemListMap.checkItemList" :color="checkItemListColors"
              :auto="[65, 90]">
              <div class="check-problem-chart-title">{{ checkProblemOverviewInfo.checkItemListMap.total }}</div>
              <div class="check-problem-chart-des">施工现场</div>
            </zy-chart-ring>
          </div>
          <div class="check-problem-tips">
            <div class="check-problem-tips-item"
              v-for="(data, index) in checkProblemOverviewInfo.checkItemListMap.checkItemList" :key="index">
              <div class="check-problem-tips-item-icon"
                :style="{ background: checkItemListColors[index] || '#FFB822' }">
              </div>
              <div class="check-problem-tips-item-title">{{ data.name }}</div>
            </div>
          </div>
        </div>
        <zy-title>隐患整改情况</zy-title>
        <danger></danger>
        <zy-title class="jxyh-margin">AI监控</zy-title>
        <div class="monitor">
          <zy-monitor v-if="points.cameraIndexCode" :channelNo="points.channelNo"
            :cameraIndexCode="points.cameraIndexCode" template-id="d255475d2b9e4cb2908853fc62d9584b"
            @click="towerCraneClick"></zy-monitor>
        </div>
      </zy-layout-column>
      <template slot="dialog">
        <!-- 视频AI监测 -->
        <zy-dialog :visible.sync="aiVisible" width="700px" title="视频AI监测">
          <div style="overflow: hidden">
            <zy-table :data="aiList" :height="459">
              <el-table-column label="设备名称" prop="cameraName" show-overflow-tooltip></el-table-column>
              <el-table-column label="报警类型" prop="eventTypeStr" width="150"></el-table-column>
              <el-table-column label="抓拍照片" prop="eventPicUrl">
                <template slot-scope="{ row }">
                  <div style="display: flex">
                    <img class="ai-img" :src="row.eventPicUrl" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="报警时间" width="200" align="center" prop="eventHappenTime"></el-table-column>
            </zy-table>
          </div>
        </zy-dialog>
        <!-- 无人塔吊 -->
        <zy-dialog title="无人塔吊" :visible.sync="towerCraneVisible">
          <zy-monitor v-if="points.cameraIndexCode" :channelNo="points.channelNo"
            :cameraIndexCode="points.cameraIndexCode" template-id="d255475d2b9e4cb2908853fc62d9584b"></zy-monitor>
        </zy-dialog>
        <zy-dialog :visible.sync="maxMarksVisible" title="数字工地">
          <zy-marks :src="points.imageUrl" v-model="points.points" :basis-width="points.imageWidths"
            @clickPoint="markClickPoint" @clickImg="showMaxMarks">
          </zy-marks>
        </zy-dialog>
      </template>
    </zy-layout>
  </div>
</template>

<script>
import components from "@/view/jxyh/components"
import MarkMonitor from "./components/markMonitor.vue"
import Card from "./components/card.vue"
import Danger from "./components/danger.vue"
import Boundary from "./components/boundary.vue"
import { specialInspections, majorHazards, checkProblemOverview, videoAiDetection, diagramHead, contentList } from "@/jxyh/api/safetyManagement"
export default {
  ...components(["ZyImage", "ZyLayout", "ZyLayoutColumn", "ZyTitle", "ZyScrollTable", "ZyScrollTableColumn", "ZyMarks", "ZyChartRing", "ZyDialog", "ZyTable", "ZyMonitor"], { MarkMonitor, Card, Danger, Boundary }),
  data() {
    return {
      aiVisible: false,
      towerCraneVisible: false,
      maxMarksVisible: false,
      aiList: [],
      specialInspectionsInfo: {},
      majorHazardsList: [],
      tower: {},
      checkProblemOverviewInfo: null,
      checkItemListColors: ["#FFB822", "#F64AFC", "#9042FF", "#0360F5", "#FC4A66", "#007FF4", "#19FBFF", "#FC8A4A", "#66BBF9", "#63E881"],
      points: {},
      point: {},
    }
  },
  computed: {
    specialInspectionsCards() {
      return [
        {
          icon: require("../../assets/icons/icon_jcrws.png"),
          label: "检查任务数",
          value: this.specialInspectionsInfo.inspectionTasksNum || 0,
          unit: "个",
        },
        {
          icon: require("../../assets/icons/icon_jcz.png"),
          label: "检查中",
          value: this.specialInspectionsInfo.checkingNum || 0,
          unit: "个",
        },
        {
          icon: require("../../assets/icons/icon_ywc.png"),
          label: "已完成",
          value: this.specialInspectionsInfo.doneNum || 0,
          unit: "个",
        },
      ]
    },
    checkProblemOverviewCards() {
      if (!this.checkProblemOverviewInfo) return []
      const obj = this.checkProblemOverviewInfo.checkItemListMap || {}
      return [
        {
          icon: require("../../assets/icons/icon_yhzs.png"),
          label: "隐患总数",
          value: obj.total || 0,
          unit: "个",
        },
        {
          icon: require("../../assets/icons/icon_dzp.png"),
          label: "待指派",
          value: obj.statistics[1].value || 0,
          unit: "个",
        },
        {
          icon: require("../../assets/icons/icon_dzg.png"),
          label: "待整改",
          value: obj.statistics[3].value || 0,
          unit: "个",
        },
        {
          icon: require("../../assets/icons/icon_dxx.png"),
          label: "待销项",
          value: obj.statistics[2].value || 0,
          unit: "个",
        },
        {
          icon: require("../../assets/icons/icon_yxx.png"),
          label: "已销项",
          value: obj.statistics[0].value || 0,
          unit: "个",
        },
      ]
    },
  },
  mounted() {
    this.getVideoAiDetection()
    this.getSpecialInspections()
    this.getMajorHazards()
    this.getCheckProblemOverview()
    this.getDiagramHead()
    this.getContentList()
  },
  methods: {
    // 视频AI监测弹窗
    showAiDialog() {
      this.aiVisible = true
    },
    //边界防护告警
    boundaryDialog() { },
    getSpecialInspections() {
      specialInspections({ viewType: 0 })
        .then((res) => {
          this.specialInspectionsInfo = res.data || {}
        })
        .catch((_) => { })
    },
    getMajorHazards() {
      majorHazards()
        .then((res) => {
          this.majorHazardsList = res.data || []
        })
        .catch((_) => { })
    },
    getCheckProblemOverview() {
      checkProblemOverview()
        .then((res) => {
          this.checkProblemOverviewInfo = res.data || {}
        })
        .catch((_) => { })
    },
    getVideoAiDetection() {
      videoAiDetection()
        .then((res) => {
          this.aiList = res.data || []
        })
        .catch((_) => { })
    },
    getDiagramHead() {
      diagramHead()
        .then((res) => {
          const data = res.data || {}
          //禁用状态情况点位信息
          if (data.pointEnabled == 1) {
            data.points = []
          }
          if (!data.points) data.points = []
          data.points?.forEach((point) => {
            point.x = point.poinAbscissa
            point.y = point.poinOrdinate
          })
          this.points = data
        })
        .catch((_) => { })
    },
    getContentList() {
      contentList({ viewEnum: 5 })
        .then((res) => {
          this.tower = res.data[0] || {}
        })
        .catch((_) => { })
    },
    markClickPoint(point) {
      this.point = point
    },
    closeMarkMonitor() {
      this.point = {}
    },
    towerCraneClick() {
      this.towerCraneVisible = true
    },
    showMaxMarks() {
      this.maxMarksVisible = true
    },
  },
}
</script>

<style lang="less">
.ai {
  &-img {
    width: 88px;
    height: 50px;
  }
}

.major-hazards-time {
  text-wrap: wrap;
  font-size: 14px;
  line-height: 15px;
}

.check-problem {
  margin: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;

  &-tips {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 50px;
    margin-top: 24px;

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      width: calc(100% / 3);
      height: fit-content;
      margin-bottom: 20px;

      &-icon {
        width: 10px;
        height: 10px;
        border-radius: 2px;
      }

      &-title {
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        line-height: 18px;
        margin-left: 6px;
      }
    }
  }

  &-chart {
    width: 170px;
    height: 170px;
    flex-shrink: 0;

    &-title {
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
      line-height: 24px;
    }

    &-des {
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      line-height: 21px;
      margin-top: 8px;
    }
  }
}

.monitor {
  width: 100%;
  aspect-ratio: 16/9;
}

.page-animation-center .zy-layout-column {
  justify-content: space-between;
}
</style>
