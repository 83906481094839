<template>
  <div class="safety-danger">
    <div
      class="safety-danger-row"
      v-for="(row, rowIndex) in list"
      :key="`row_${rowIndex}`"
    >
      <div
        v-for="(card, index) in row"
        :key="`card_${index}`"
        class="safety-danger-row-card"
      >
        <img :src="card.icon" />
        <div class="safety-danger-row-card-content">
          <div>
            <div :style="{ color: card.color }">
              <count-to
                :endVal="card.value"
                :decimals="card.decimals"
              ></count-to>
            </div>
            <div :style="{ color: card.color }">{{ card.unit }}</div>
          </div>
          <div>{{ card.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { rectificationSituation } from "@/jxyh/api/safetyManagement"
import CountTo from "@/components/count-to.vue"
export default {
  components: { CountTo },
  data() {
    return {
      info: {},
    }
  },
  computed: {
    list() {
      return [
        [
          {
            label: "延期整改",
            icon: require("../../../assets/icons/icon_yqzg.png"),
            value: this.info.delayRectify || 0,
            unit: "项",
            color: "#FC4A66",
          },
          {
            label: "延期未整改",
            icon: require("../../../assets/icons/icon_yqwzg.png"),
            value: this.info.delayNoRectify || 0,
            unit: "项",
            color: "#FC4A66",
          },
        ],
        [
          {
            label: "按时整改率",
            icon: require("../../../assets/icons/icon_zgl.png"),
            value: this.info.rectifyRate || 0,
            unit: "%",
            color: "#ffffff",
            decimals: 1,
          },
          {
            label: "平均整改时间",
            icon: require("../../../assets/icons/icon_pjzgsj.png"),
            value: this.info.rectifyTime || 0,
            unit: "天",
            color: "#ffffff",
            decimals: 1,
          },
        ],
      ]
    },
  },
  created() {
    this.getRectificationSituation()
  },
  methods: {
    getRectificationSituation() {
      rectificationSituation()
        .then((res) => {
          this.info = res.data || {}
        })
        .catch((_) => {})
    },
  },
}
</script>

<style lang="less" scoped>
.safety-danger {
  width: 100%;
  display: flex;
  flex-direction: column;
  &-row {
    display: flex;
    flex-direction: row;
    &-card {
      flex: 1;
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      justify-items: center;
      & + & {
        margin-left: 10px;
      }
      & > img {
        width: 64px;
        height: 64px;
      }
      &-content {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        & > div:nth-child(1) {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          div:nth-child(1) {
            font-weight: bold;
            font-size: 24px;
            line-height: 31px;
          }
          div:nth-child(2) {
            font-size: 14px;
            color: #fc4a66;
            line-height: 25px;
            margin-left: 5px;
          }
        }
        & > div:nth-child(2) {
          font-size: 14px;
          color: #66bbf9;
          line-height: 21px;
          margin-top: 7px;
        }
      }
    }
  }
}
</style>
