<template>
  <zy-dialog
    :visible.sync="visible"
    :title="title"
    @close="closePopover"
    width="1000px"
    padding="12px 20px"
  >
    <div class="mark-monitor-content">
      <div class="mark-monitor-content-img">
        <zy-monitor
          :channelNo="point.channelNo"
          :cameraIndexCode="point.cameraIndexCode"
          :templateId="templateId"
        ></zy-monitor>
      </div>
      <div class="mark-monitor-content-address">
        <img src="../../../assets/icons/icon_address.png" />
        <div>{{ point.pointName }}</div>
      </div>
    </div>
  </zy-dialog>
</template>

<script>
import components from "@/view/jxyh/components"
import Bus from "@/view/jxyh/components/zy-marks/bus"
export default {
  ...components(["ZyMonitor", "ZyDialog"]),
  data() {
    return {
      video: {
        cameraIndexCode: "J79407237",
        channelNo: "2",
      },
      templateId: "d255475d2b9e4cb2908853fc62d9584b",
      visible: false,
    }
  },
  watch: {
    point: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.id && val.id.length) {
          this.visible = true
        } else {
          this.visible = false
        }
      },
    },
  },
  computed: {
    title() {
      return `${this.point.cameraName}\xa0\xa0\xa0${this.point.currentTime}`
    },
  },
  props: {
    point: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    closePopover() {
      this.$emit("close")
      Bus.$emit("show-popover", null)
    },
  },
}
</script>

<style lang="less">
.mark-monitor {
  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-img {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }

    &-address {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 13px;
      & > img:nth-child(1) {
        width: 12px;
        height: 14px;
      }
      & > div:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        margin-left: 6px;
      }
    }
    &-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        & > div:nth-child(1) {
          font-weight: 500;
          font-size: 14px;
          color: #66bbf9;
          line-height: 20px;
        }
        & > div:nth-child(2) {
          font-size: 12px;
          color: #66bbf9;
          line-height: 17px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
